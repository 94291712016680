// global variables
.img_config {
  max-width: 100%;
  max-height: 100%;
}

.edit_img_config {
  max-width: 100%;
  max-height: 100%;
  margin-right: 10%;
}

@mixin titleText {
  font-size: 1.3vw;
  font-weight: 630;
  color: #6c757e;
}
.titleText {
  font-size: 1.3vw;
  font-weight: 630;
  color: #6c757e;
  margin: 20px;
}

.panel_titleText {
  font-size: 1.1vw;
  font-weight: 630;
  color: #6c757e;
  margin: 0px;
}



@mixin paramText1 {
  font-size: 1vw;
  font-weight: 450;
  color: #6c757e;
}
.paramText1 {
  font-size: 1vw;
  font-weight: 450;
  color: #6c757e;
}
@mixin buttonStyle {
  border-radius: 10px;
  box-shadow: 5px 5px 5px #c8c7c7, -3px -3px 3px white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonStyle {
  border-radius: 10px;
  box-shadow: 5px 5px 5px #c8c7c7, -3px -3px 3px white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin buttonText {
  font-size: 0.85vw;
  font-weight: 500;
  color: #6c757e;
}
.buttonText {
  font-size: 0.85vw;
  font-weight: 500;
  color: #6c757e;
}

@mixin valueText {
  font-size: 1vw;
  font-weight: 400;
  color: #484c4f;
}
.valueText {
  font-size: 1vw;
  font-weight: 400;
  color: #484c4f;
  margin-left: 5%;
}

@mixin inputStyle {
  background-color: white;
  border: 1px solid #acacac;
  border-radius: 10px;
}
.inputStyle {
  background-color: white;
  border: 1px solid #acacac;
  border-radius: 10px;
}

@mixin subtitleText {
  font-size: 1vw;
  font-weight: 500;
  color: #4d5154;
  display: flex;
  align-items: center;
}
.subtitleText {
  font-size: 1vw;
  font-weight: 500;
  color: #4d5154;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.root_con {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f2f2f2;
}

.body_con {
  width: 100%;
  height: 100%;
}

.main_con {
  display: flex;
  height: 84%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.con_border {
  border: 1px solid rgb(206, 206, 206);
  border-radius: 15px;
  box-shadow: 5px 5px 10px #d8d8d8, -5px -5px 5px #fcfcfc,
    inset -5px -5px 5px #fbfbfb, inset 3px 3px 5px #e8e8e8;
}

.paramText2 {
  font-size: 1vw;
  font-weight: 400;
  color: #6c757e;
}

.top_con {
  width: 90%;
  height: 65%;
  display: flex;
  justify-content: space-between;
}


.sig_conB_item {
  border-bottom: 1px solid #cecece;
  width: 93%;

  padding: 10px 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.subtitleTextList {
  font-size: 1vw;
  font-weight: 500;
  margin-bottom: 10px;
  color: #4d5154;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sig_info {
  justify-content: space-between;
  @include subtitleText;
  flex-direction: column;
}

.sig_param {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.sig_conB_item_body_part {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 4%;
  padding-right: 4%;
  justify-content: space-around;
}

.paramText{
  font-size: 1vw;
  font-weight: 400;
  color: #6c757e;
}

.sig_conB_item_body {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sig1_con {
  width: 33%;
  height: 100%;
}

.sig1_conA {
  display: flex;
  justify-content: space-between;
  width: 95%;
  height: 10%;
  margin-left: 6%;
  display: flex;
  align-items: center;
}

.sig1_conB {

  height: 75%;
  padding: 0 2%;
  margin-top: 4%;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.sig1_conB_item {
  border-bottom: 1px solid #cecece;
  width: 93%;
  height: 50%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sig2_con {
  width: 65%;
  height: 100%;
}

.sig2_conA {
  height: 10%;
  padding: 0 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sig2_conB {
  width: 95%;
  height: 75%;
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.sig2_conB_item {
  border-bottom: 1px solid #cecece;
  width: 93%;
  height: 50%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sig2_conB_item_body {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sig2_conB_item_body_part {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 4%;
  padding-right: 4%;
  justify-content: space-around;
}

.bottom_con {
  width: 90%;
  height: 25%;
  margin-bottom: 2%;
}

.bottom_conA {
  height: 30%;
  width: 97%;
  margin-left: 1%;
  display: flex;
  align-items: center;
}

.bottom_conB {
  height: 30%;
  width: 75%;
  margin-left: 3%;
  display: flex;
  align-items: center;
}

.modal_con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal_body {
  margin-left: 5%;
  width: 95%;
  height: 60%;
  overflow-y: auto;
}

.modal_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 15%;
}

.modal_button_row {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 6%;
}
.sig_button {
  height: 100%;
  width: 30%;
  @include buttonStyle;
  @include buttonText;
}
.sig_button_init {
  height: 100%;
  width: 30%;
  background-color: "#127ca4";
  color: "white";
  @include buttonStyle;
  @include buttonText;
}
.modal2_body {
  margin-left: 5%;
  margin-top: 3%;
  width: 95%;
  height: 55%;
  display: flex;
  justify-content: space-between;
}

.modal2_body_column {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal2_row {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 30%;
}

.rewrite_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 30%;
  max-height: 300px;
  overflow-y: auto;
  margin-left: 6%;
  font-size: 1vw;
}

.disable_event_list{
  max-height: 300px;
  overflow-y: auto;
  margin: 10px;
}
.modal_header_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 15px;
  height: 15%;
}
.sig_title {
  margin-top: 2%;
  @include titleText;
}

.sig_close_modal{
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	outline: inherit;
  margin-top: 2%;
  font-weight: 900;
  margin-right: 2%;
  font-size: 1.2rem;
  &:hover{
    cursor: pointer;
  }
}
.sig1_confirm_text {
  margin-top: 4%;
  margin-left: 5%;
  @include paramText1;
}

.sig2_confirm_text {
  margin-top: 8%;
  margin-left: 5%;
  @include paramText1;
}


.sites_label{
  @include paramText1;
}

.sites_list {
  max-height: 200px;
  overflow-y: auto;
  margin: 10px 20px;
  font-size: 1vw;
}
.select_site,.modal_subheader {
  margin-top: 2%;
  margin-left: 5%;
  @include paramText1;
}

.sig_button {
  height: 100%;
  width: 30%;
  @include buttonStyle;
  @include buttonText;
}
.sig_button_init {
  height: 100%;
  width: 30%;
  background-color: "#127ca4";
  color: "white";
  @include buttonStyle;
  @include buttonText;
}
.sig1_calibration {
  margin-top: 4%;
  margin-left: 5%;
  height: 5%;
  @include titleText;
}

.phone {
  width: 30%;
  @include paramText1;
}
.phone_input {
  width: 25%;
  margin-right: 5%;
  @include valueText;
  &:hover {
    border: 1px solid black;
  }
}
.final_sites_signal_event{
  color: #117ca4;
  font-weight: 600;
  margin-top: 5px; 
}

.sms {
  width: 20%;
  @include paramText1;
}

.sig1_input {
  padding: 2%;
  margin-top: 1%;
  margin-bottom: 5%;
  margin-left: 5%;
  width: 70%;
  height: 12%;
  @include inputStyle;
}
.sig1_icon{
  max-height: 70% !important;
  margin-left: 3% !important;
}
.sig2_label{
  margin-bottom: 5%;
}
.sig2_input {
  padding-left: 2%;
  margin-top: 1%;
  width: 90%;
  height: 40%;
  @include inputStyle;
}
.sig2_icon{
  max-height: 40% !important;
  margin-left: 3% !important;
}
.email {
  width: 30%;
  @include paramText1;
}
.email_input {
  width: 25%;
  margin-right: 5%;
  @include valueText;
  &:hover {
    border: 1px solid black;
  }
}

.sig2_param {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.help_con {
  height: 30%;
  margin-left: 1%;
}
.edit_con {
  width: 9%;
  height: 50%;
  margin-left: 5%;
  @include buttonStyle;
}
.sig1_param_header {
  margin-top: 0%;
  margin-left: 5%;
  margin-bottom: 4%;
  height: 5%;
  @include paramText1;
}
.sig1_param {
  display: flex;
  align-items: center;
  height: 10%;
  margin-top: 4%;
  margin-left: 5%;
  @include paramText1;
}
.pending_status {
  color: #3b8a3c;
  text-align: right;
}
.disable_event1 {
  width: 40%;
  height: 7%;
  margin-left: 5%;
  margin-top: 5px;
  @include buttonStyle;
  @include buttonText;
}
.disable_event2 {
  width: 20%;
  height: 7%;
  margin-left: 5%;
  margin-top: 5px;
  @include buttonStyle;
  @include buttonText;
}
.delete_logo_con {
  width: 7%;
  height: 100%;
  margin-left: 20%;
}
.sig2_info {
  justify-content: space-between;
  @include subtitleText;
}

.add_sig1_event {
  width: 40%;
  height: 60%;
  margin-left: 0%;
  margin-right: 10%;
  @include buttonStyle;
  @include buttonText;
}
.add_sig2_event {
  width: 20%;
  height: 60%;
  margin-left: 5%;
  @include buttonStyle;
  @include buttonText;
}
.pending_color {
  color: #3b8a3c;
}
.delete_con {
  width: 35px;
  height: 35px;
  margin-left: 20%;
  @include buttonText;
  @include buttonStyle;
}

.selectAllCon {
  display: flex;
  align-items: center;
  margin-left: 5%;
  @include paramText1;
}

/* event log styles */

.event_log__button {
  background-color: #f2f1f1;
  border: 1px solid #f2f1f1;
  border-radius: 5px;
  box-shadow: 6px 6px 6px #dbdbdb, -3px -3px 5px #ffffff;
  color: #858c94;
  width: 20%;
  height: 100%;
  cursor: pointer;
}

.event_log__button_text{
  @include buttonText
}

.event_log__button_icon{
   color: #858c94;
}

.event_log__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.event_log__top_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.event_log__header {
  margin-top: 2%;
  margin-left: 5%;
  @include titleText;
}

.event_log__close_button {
  margin-top: 2%;
  margin-right: 2%;
}

.event_log__date_header {
  margin-top: 2%;
  margin-left: 5%;
  font-size: 1.3vw;
  font-weight: 600;
  border-top: 1px solid #858c94;
}

.event_log__date_body {
  overflow-y: auto;
}

.event_log__date_signal {
  display: flex;
  align-items: center;
  @include paramText1;
}

.event_log__date__body_section {
  margin-top: 5%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.enable_signal_systems_list_soc {
  color: #117ca4;
  font-weight: 600;
}

.display_params {
  width: 60%;
  height: 18%;
  margin: 1% 4% 1% 4%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display_params_value {
  color: #117ca4; 
  font-weight: 600;
}