
.historical_container{
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
}

.historical_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.historical_header_left{
    width: 50%;
    font-size: 1.5rem;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.historical_header_right{
    justify-content: right;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.historical_body{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.historical_value_container {
    box-shadow: inset -2px -2px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    margin-right: 2%;
    margin-bottom: 2%;
    padding: 1% 2%;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}


.historical_value_title {
    margin: 0;
    font-weight: 400;
    color: #484c4f;
    font-size: 1rem;
    margin-bottom: 2%;
}

.historical_value_label {
    margin: 0;
    font-weight: 400;
    color: green;
    font-size: 1.6rem;
}

.react-date-picker__wrapper {
    border: 1px solid #f2f2f2 !important;
    box-shadow: 3px 3px 3px #b4b4b4, -2px -2px 3px #eeeeee !important;
    border-radius: 10px !important;
    padding: 10px !important;
}