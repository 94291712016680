.root_container {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
}

.main_container {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.session_con {
  height: 80%;
  width: 90%;
}

.query_con {
  height: 10%;
  width: 90%;

  display: flex;
  align-items: center;
}

.id_input {
  border: 1px solid black;
  margin-left: 5%;
}
.chargerid_con {
  width: 25%;
}
