.root {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #f2f1f1;
  overflow: auto;
  flex-grow: 1;
  gap: 10px;
  border: 1px solid #d3d3d3;
    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;
    border-radius: 15px;
  height: auto;
  color: #4f5153;
  font-family: open sans, sans-serif;
}

.header {
  font-weight: 600;
  font-size: 1.25rem;
}


@media (max-width: 1000px) {
  .root {
    width: 100%;
  }
}