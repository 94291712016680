input {
  background-color: transparent;
  border: transparent;
}

.header1 {
  font-size: 1vw;
  font-weight: 500;
  color: #6d7377;
}

.header2 {
  font-size: 1vw;
  font-weight: 600;
  color: #5f6468;
}

.inputStyle {
  border-radius: 7px;
  background-color: white;
  box-shadow: inset 2px 2px 3px #c3c3c3;
}

.root_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f2f2f2;
}

.right_container {
  width: 90%;
  height: 100%;
}

.main_container {
  height: 90%;
  width: 90%;
  margin: auto;
  margin-top: 2%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.param_con {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
}

.param_itemA {
  margin-right: 1%;
  width: 18%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.param_header_con {
  width: 100%;
}

.param_itemA_B {
  width: 100%;
  height: 40%;
}

.param_itemB {
  margin-left: 3%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.graph_con {
  justify-content: center;
}

.graph_scroll_con {
  width: 95%;
  height: 100%;
}

.bar_chart_header {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
}

.bar_chart_header_label {
  font-size: 1.3vw;
  font-weight: 500;
  color: #6c757d;
  margin-left: 3%;
}

.bar_chart {
  border-radius: 15px;
  background-color: white;
  margin-left: 0px;
  margin-top: 5%;
  margin-bottom: 20%;
  width: 100%;
  height: 100%;
  display: flex;
}

.picker_row {
  display: flex;
}
.picker_item {
  margin-right: 5%;
  z-index: 9999;
}
.param_itemC_con{
  display: flex;
  align-items: flex-start;
}

.param_itemC{
  width: 80%;
  margin-right: 6%;
  margin-left: -3%;
}

.param_itemD{
  display: flex;
}
.cost{
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
