
.revenue_container{
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
}

.revenue_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.revenue_header_left{
    width: 50%;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
}
.revenue_header_right{
    width: 50%;
    justify-content: right;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.revenue_body{
    display: flex;
    padding: 1%;
    padding-left: 4%;
    flex-direction: column;
    margin-top: 2%;
    justify-content: stretch;
    align-items: stretch;
    height:95%;
}

.button_filter {
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 3px #b4b4b4, -2px -2px 3px #eeeeee;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}


.button_filter_active{
    border: 1px solid #484c52;
    box-shadow: 3px 3px 3px #b4b4b4, -2px -2px 3px #eeeeee;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    background-color: #484c52;
    color: white;
}