.navbar__container {
  width: 100%;
  min-height: 40px;
  background-color: #6c757e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
}
.navbar__dropdown {

}


.navbar__button {
  color: black;
  font-size: 0.9vw;
}

.userIcon {
  height: 20px;
  border-radius: 50%;
}
