.root_container {
    width: 100%;
    height: 100%;
    background-color: #f2f1f1;
}

.main_container {
    height: 90%;
    display: flex;
    flex-direction: column;
}
.buttonlist_con {
    width: 50%;
    height: 6%;
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
}
.scrollCon {
    overflow-y: scroll;

    height: 90%;
    width: 100%;

    margin-top: 2%;
}

.button_con {
    height: 100%;
    width: 20%;

    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 3px 3px 3px #b0b2b4, -3px -3px 3px #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    font-size: 1vw;
}


.batterymodule_row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5%;
    margin-top: 5%;

    width: 100%;
    height: 75%;
}
.batterymodule_con{
    width: 20%;
    height: 100%;
  
}
