.SideBarContainer {
  width: 10%;
  height: 100%;
  float:left;
  background-color: #3c4042;
  position: relative;
}

.companyLogo {
  height: 5%;
  margin: 7% 0%;
}

.SideBarList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.content {
  float: left;
  height: 100%;
  width: 90%;
  min-width: 0;
  box-sizing: border-box;
}

.category {
  width: 100%;
  height: 8%;
  transition: width 0.5s, height 0.5s;
  display: flex;
  align-items: center;
}

.categoryInnner {
  display: flex;
  width: 100%;
  height: 60%;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
}

.category:hover {
  background-color: #eaeaea;
}

.category:hover > .categoryInnner {
  background-color: #eaeaea;
  border: 1px solid #dedede;

  border-radius: 0px 10px 10px 0px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380,
    10px 5px 5px #d6d8da, -10px -10px 10px #f5f5f5;
}

.categoryInner_selected {
  background-color: #eaeaea;
  border: 1px solid #dedede;

  border-radius: 0px 10px 10px 0px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380,
    10px 5px 5px #d6d8da, -10px -10px 10px #f5f5f5;

  display: flex;
  width: 100%;
  height: 60%;
  align-items: center;
  justify-content: space-around;

  cursor: pointer;
}
.categoryInner_selected div {
  font: normal normal bold Open Sans;
  color: #308aad;
  font-weight: 700;
  font-size: 0.9vw;
}

.categoryGA {
  width: 100%;
  height: 30%;
  transition: width 0.5s, height 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eaeaea;
}

.categoryGAInnner {
  display: flex;
  width: 100%;
  height: 30%;
  align-items: center;
  justify-content: space-around;

  border: 1px solid #dedede;
  border-radius: 0px 10px 10px 0px;

  cursor: pointer;
}

.categoryGAOutter {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: space-around;

  background-color: #f2f1f1;

  border: 1px solid #dedede;
  border-radius: 0px 10px 10px 0px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380,
    5px 5px 5px #d6d8da;
}

.subcategoryGAInnner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 80%;
  cursor: pointer;
}

.subcategoryGAtext {
  color: #b8babe;
  margin-left: 30%;
  font-size: 0.7vw;
}

.subcategoryGAtext:hover {
  color: #308aad;
}
.subcategoryGAtext_selected {
  color: #308aad;
  margin-left: 30%;
  font-size: 0.7vw;
}

.categoryImgContainer {
  height: 70%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryImg {
  max-height: 100%;
  max-width: 100%;
  display: flex;
}

.categoryWord {
  width: 60%;
  height: 100%;
  font: normal normal bold Open Sans;
  color: #aeb2b5;
  font-weight: 400;
  font-size: 0.7vw;
  display: flex;
  align-items: center;
}

.categoryWordSelected {
  width: 70%;
  height: 100%;
  font: normal normal bold Open Sans;
  color: #308aad;
  font-weight: 700;
  font-size: 0.9vw;
  display: flex;
  align-items: center;
}

.navbarLogoutButton {
  font-size: 0.7vw;
  border: 0px;
}

.sidebar_category_bottom {
  border: 1px solid black;
  width: 75%;
  height: 4%;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 3%;
  background-color: #f2f2f2;
  color: #3c4042;
  margin-left: 10%;
  font-size: 0.9vw;
  cursor: pointer;
}

.sidebar_category_top {
  width: 85%;
  height: 4%;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 2%;
  color: #e2e2e2;
  margin-left: 10%;
  font-size: 0.7vw;
}

.userIcon {
  max-height: 100%;
  max-width: 100%;
  display: flex;
  border-radius: 50%;
}
