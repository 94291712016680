.marker {
   cursor: pointer;
   font-size: 2.8rem !important;
}

.green{
    color: green;
    &:hover,
    &.selected{
        font-size: 3.4rem !important;
        color: #2dd842;
    }
}
.grey{
    color: #484848;
    &:hover,
    &.selected{
        font-size: 3.4rem !important;
        color: grey;
    }
}
.red{
    color: #8f0f08;
    &:hover,
    &.selected{
        font-size: 3.4rem !important;
        color: #ff0000;
    }
}
.orange{
    color: #b27805;
    &:hover,
    &.selected{
        font-size: 3.4rem !important;
        color: orange;
    }
}

.back_circle{
    position: absolute;
    z-index : -2;
    // padding: 20px;
    content: "";
    left: 10 ;
    color: white; 

}
