
.modal_con {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal_header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15%;
}

.modal_tabs {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 5px 10px;
}

.event_log__close_button {
    margin-top: 2%;
    margin-right: 2%;
}


.button_filter {
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 3px #b4b4b4, -2px -2px 3px #eeeeee;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}


.button_filter_active{
    cursor: pointer;
    border: 1px solid #484c52;
    box-shadow: 3px 3px 3px #b4b4b4, -2px -2px 3px #eeeeee;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    background-color: #484c52;
    color: white;
}

.modal_body {
    padding-bottom: 2%;
    min-height: 300px;
    overflow-y: auto;
}

.subheader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.stacked_header_left{
    padding-left: 2%;
    width: 50%;
    font-size: 0.85rem;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.stacked_header_right{
    width: 50%;
    justify-content: right;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.label_for_switch{
    font-size: 1.2rem;
    margin-right: 10px;
}

.container{
    height: 100%;
    display: flex;
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

