.headbarContainer {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.headbarTitle {
  width: 50%;
  height: 100%;
  margin-left: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.headbarAlerts_con {
  width: 40%;
  height: 85%;

  border-radius: 15px;
  /* border: 1px solid #da7354; */
  /* box-shadow: 4px 4px 4px #cccccc; */

  display: flex;
  justify-content: space-between;
}

.headbarAlerts_view {
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #127ca4
}

.headbarAlerts_info {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headbarAlerts_status_con {
  display: flex;
  height: 40%;
  padding-left: 8%;
}

.headbarAlerts_text {
  height: 40%;

  display: flex;
  align-items: center;
  padding-left: 15%;

  color: #6e777e;
}

.headbaritemList {
  width: 23%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-right: 3%;
}

.headbarTitle1 {
  margin: auto;
  margin-left: 6%;
  color: #5a6268;
  font-size: 1.5vw;
}

.headbarTitle2 {
  margin: auto;
  margin-left: 6%;
  color: #5a6268;
  font-weight: 400;
  font-size: 1vw;
}




.ChargeHubConnect {
  width: 100%;
  height: 90%;

  font-size: 1.2vw;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  border: 1px solid #DEDEDE;
  border-radius: 10px;
  box-shadow: inset -4px -4px 3px #F9F9F9, inset 4px 4px 4px #d3d3d380;

}

.SearchContainer {
  display: flex;
  width: 25%;
  height: 50%;

  border: 1px solid #DEDEDE;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #F9F9F9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #D6D8DA, -10px -10px 10px #F5F5F5;
}

.SearchLogoContainer {
  width: 10%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Search {
  border-style: none;
  width: 80%;
  padding-left: 5%;
}

.headbarlist_container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
}

.headbarSettingContainer {
  width: 6%;
  height: 30%;
  margin-left: 7%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.headbarNotiContainer {
  width: 6%;
  height: 30%;
  margin-left: 2%;


  display: flex;
  align-items: center;
  justify-content: center;
}

.calenderContainer {
  width: 20%;
  margin-left: 5%;


  display: flex;
  align-items: center;
  justify-content: center;
}





.headbarLogout {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 40%;
  margin-left: 5%;

}

.headbarLogoutButton {
  font-size: 0.7vw;
  width: 20%;
}

.headbarLogoutProfile {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 20%;
}

.userlogo_container {
  width: 50%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headbarLogoutUser {
  font-size: 0.9vw;
  font-weight: 400;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.headbarLogo {
  max-width: 100%;
  max-height: 100%;
}


textarea:focus,
input:focus {
  outline: none;
}