:root {
  --sidelength: 6px;
  --line1duration: 2.5s;
  --line2duration: 2.2s;
  --line3duration: 2.2s;
  --line4duration: 2.8s;
  --line5duration: 2.2s;
  --line6duration: 2.2s;
  --line7duration: 2.2s;
  --line8duration: 2.2s;
  --sidelength1: 6px;
  --borderRadius1: 50%;
}

.site_main_container {
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f2f2f2;
}

.body_con {
  height: 92%;
  width: 65%;
  border: 1px solid #dedede;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f2f2f2;
}

.site_main_left_container {
  display: grid;
  grid-template-columns: repeat(100, 1%);
  grid-template-rows: repeat(77, 1.3%);
  position: relative;
  height: 87%;
  width: 93%;

  justify-items: center;
  align-items: center;

  z-index: 1;
  font-size: 1.2vw;
}

.buttonA {
  width: 50%;
  margin-left: 50%;
  margin-right: 5%;
  font-weight: 500;
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.buttonA_item {
  display: flex;
  height: 100%;
  width: 33%;
}
.buttonA_item_text {
  margin-right: 5%;
}

/* door sensor  */
.body_conB {
  height: 92%;
  width: 65%;
  border: 1px solid #dedede;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
}

.door_conA {
  width: 93%;
  height: 10%;

  display: flex;
  align-items: center;
}

.door_conA_A {
  width: 20%;
  height: 100%;
  font-size: 1.2vw;
  font-weight: 600;
  color: #6a7077;

  display: flex;
  align-items: center;
}

.door_conA_B {
  width: 17%;
  height: 55%;

  color: #6c757d;
  font-weight: 600;
  font-size: 0.9vw;

  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #b0b2b4, -3px -3px 3px #e7e7e7;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.door_conB {
  width: 93%;
  height: 88%;

  box-shadow: inset -4px -4px 3px #ffffff, inset 4px 4px 4px #d3d3d380;
  border-radius: 5px;
}

.door_conB_A {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;

  color: #575d61;
  font-weight: 600;
  font-size: 0.8vw;

  width: 90%;
  height: 5%;
  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.door_conB_B {
  color: #575d61;
  font-weight: 600;
  font-size: 0.8vw;
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.door_conB_B_row {
  display: flex;
  border: 1px solid #dbdbdb;
  width: 100%;
  height: 7%;

  font-weight: 300;
}

.door_conB_B_rowA {
  width: 14%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.door_conB_B_rowB {
  margin-left: 27%;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.door_conB_B_rowC {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charger_con {
  position: absolute;
  bottom: -4%;
  right: 5%;
  display: flex;
  width: 50%;
  justify-content: space-between;
  margin-block: 20px;
}

.SiteOverview_object_title {
  font-size: var(--fontSize1);
  font-weight: 600;
  color: #6c757e;
  text-align: center;
}

/* pipeline 1 */
@keyframes leftToright1 {
  0% {
    margin-left: 0%;
  }

  100% {
    margin-left: 75%;
  }
}

@keyframes topTobottom1 {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(250%);
  }
}

@keyframes RightToLeft1 {
  0% {
    margin-left: 75%;
  }

  100% {
    margin-left: 0%;
  }
}

@keyframes BottomToTop1 {
  /* from {
    transform: translateY(250%);
  }

  to {
    transform: translateY(0%);
  } */
  0% {
    height: 75%;
  }

  100% {
    height: 0%;
  }
}

.l1_A_con {
  width: 100%;
  height: 100%;
  grid-column-start: 28;
  grid-column-end: 37;
  grid-row-start: 15;
  grid-row-end: auto;
  position: relative;
  background-color: #74abb4;
  z-index: 2;
}

.l1_A_item {
  position: absoulte;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(to left, rgb(6, 247, 247), #74abb4);
  animation: leftToright1 1s linear normal infinite;
}

.l1_A_item_neg {
  position: absoulte;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(to right, rgb(6, 247, 247), #74abb4);
  animation: RightToLeft1 1s linear normal infinite;
}

.l1_B_con {
  width: 100%;
  height: 100%;
  grid-column-start: 36;
  grid-column-end: auto;
  grid-row-start: 15;
  grid-row-end: 26;
  position: relative;
  background-color: #74abb4;
  z-index: 1;
}

.l1_B_item {
  /* position: absoulte; */
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom1 1s linear normal infinite;
}

.l1_B_item_neg {
  /* position: absoulte; */
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to bottom, rgb(6, 247, 247), #74abb4);
  animation: BottomToTop1 1s linear normal infinite;
}

.l1_C_con {
  width: 100%;
  height: 100%;
  grid-column-start: 36;
  grid-column-end: auto;
  grid-row-start: 25;
  grid-row-end: 35;
  position: relative;
  background-color: #74abb4;
  z-index: 0;
  display: flex;
  flex-direction: column;
}

/* pipeline 2 */
@keyframes leftToright2 {
  0% {
    margin-left: 0%;
  }

  100% {
    margin-left: 75%;
  }
}

@keyframes topTobottom2 {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(250%);
  }
}

.l2_A_con {
  width: 100%;
  height: 100%;
  grid-column-start: 80;
  grid-column-end: 89;
  grid-row-start: 15;
  grid-row-end: auto;
  position: relative;
  background-color: #74abb4;
  z-index: 2;
}

.l2_A_item {
  position: absoulte;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(to left, rgb(6, 247, 247), #74abb4);
  animation: leftToright2 1s linear normal infinite;
}

.l2_B_con {
  width: 100%;
  height: 100%;
  grid-column-start: 88;
  grid-column-end: auto;
  grid-row-start: 15;
  grid-row-end: 25;
  position: relative;
  background-color: #74abb4;
  z-index: 1;
}

.l2_B_item {
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom2 1s linear normal infinite;
}

/* pipeline 3 */
@keyframes rightToleft3 {
  0% {
    margin-right: 0%;
  }

  100% {
    margin-right: 75%;
  }
}

@keyframes leftToright3 {
  0% {
    margin-right: 75%;
  }

  100% {
    margin-right: 0%;
  }
}

@keyframes topTobottom3 {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(250%);
  }
}

@keyframes bottomTotop3 {
  from {
    transform: translateY(250%);
  }

  to {
    transform: translateY(0%);
  }
}

.l3_A_con {
  width: 100%;
  height: 100%;
  grid-column-start: 62;
  grid-column-end: auto;
  grid-row-start: 25;
  grid-row-end: 35;
  position: relative;
  background-color: #74abb4;
  z-index: 2;
}

.l3_A_item {
  position: absoulte;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom3 1s linear normal infinite;
}

.l3_A_item_neg {
  position: absoulte;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to bottom, rgb(6, 247, 247), #74abb4);
  animation: bottomTotop3 1s linear normal infinite;
}

.l3_C_con {
  width: 100%;
  height: 100%;
  grid-column-start: 62;
  grid-column-end: auto;
  grid-row-start: 35;
  grid-row-end: 43;
  position: relative;
  background-color: #74abb4;
  z-index: 2;
}

.l3_C_item {
  position: absoulte;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom3 1s linear normal infinite;
}

.l3_C_item_neg {
  position: absoulte;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to bottom, rgb(6, 247, 247), #74abb4);
  animation: bottomTotop3 1s linear normal infinite;
}

.l3_B_con {
  width: 100%;
  height: 100%;
  grid-column-start: 62;
  grid-column-end: 52;
  grid-row-start: 42;
  grid-row-end: auto;
  position: relative;
  background-color: #74abb4;
  z-index: 2;

  display: flex;
  justify-content: flex-end;
}

.l3_B_item {
  position: absoulte;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(to right, rgb(6, 247, 247), #74abb4);
  animation: rightToleft3 1s linear normal infinite;
}

.l3_B_item_neg {
  position: absoulte;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(to left, rgb(6, 247, 247), #74abb4);
  animation: leftToright3 1s linear normal infinite;
}

.l4_A_con {
  width: 100%;
  height: 100%;
  grid-column-start: 72;
  grid-column-end: auto;
  grid-row-start: 25;
  grid-row-end: 35;
  position: relative;
  background-color: #74abb4;
  z-index: 3;
}

.l4_A_item {
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom1 1s linear normal infinite;
}

.l4_B_con {
  width: 100%;
  height: 100%;
  grid-column-start: 72;
  grid-column-end: auto;
  grid-row-start: 35;
  grid-row-end: 46;
  position: relative;
  background-color: #74abb4;
  z-index: 2;
}

.l4_B_item {
  /* position: absoulte; */
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom1 1s linear normal infinite;
}

.l4_C_con {
  width: 100%;
  height: 100%;
  grid-column-start: 72;
  grid-column-end: auto;
  grid-row-start: 45;
  grid-row-end: 57;
  position: relative;
  background-color: #74abb4;
  z-index: 1;
}

.l4_C_item {
  /* position: absoulte; */
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to top, rgb(6, 247, 247), #74abb4);
  animation: topTobottom1 1s linear normal infinite;
}

.root_container {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

.site-main-container {
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.activity_container {
  height: 92%;
  width: 28%;
}

.site_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #dedede;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
  height: 28%;
  width: 100%;
  transition: width 0.5s, height 0.5s;
  background-color: #f2f1f1;
}

.site_container:hover {
  height: 30%;
  width: 103%;
}

.ga_item_container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ga_item_subcontainer {
  padding-left: 5%;
  border: 0.5px solid #dedede;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.dashboard_ems_ga_enable {
  color: #117ca4;
  font-size: 0.6vw;
  font-weight: 600;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380;
  border: 1px solid #dedede;
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.capacityBar {
  width: 100%;
  height: 40%;
}

.ems_title {
  color: #5a6268;
  font-weight: 650;
  font-size: 0.8vw;
  height: 10%;
  display: flex;
  align-items: center;
}

.dashboard_ems_item {
  width: 15%;
  height: 90%;
  border: 1px solid #dedede;
  border-radius: 5px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, -1px -1px 1px #f5f5f5;
  color: #8e959b;
  font-weight: 600;
  font-size: 0.8vw;
}

.dashboard_ems_item_title {
  font-size: 0.7vw;
  font-weight: 650;
  color: #8e959b;
  margin-left: 5%;
  margin-top: 5%;
}

.BMS_item_special {
  height: 90%;
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.BMS_body_container {
  border: 1px solid #dedede;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, -1px -1px 1px #f5f5f5;
  width: 100%;
  height: 80%;
}

.BMS_body_header {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  margin-left: 5%;
  font-size: 0.7vw;
  color: #8e959b;
  font-weight: 700;
}

.dashboard_bms_title {
  margin-left: 3%;
  color: #5a6268;
  font-weight: 650;
  font-size: 0.8vw;
  display: flex;
  align-items: center;
}

.BMS_body {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.BMS_body_item {
  display: flex;
  width: 100%;
}

.BMS_body_text {
  font-size: 0.6vw;
  color: #8e959b;
  font-weight: 400;
  text-align: right;
  width: 40%;
  margin-right: 5%;
}

.BMS_body_indicator {
  font-size: 0.5vw;
  color: #4a9ab8;
  font-weight: 600;
  text-align: center;
  border-radius: 15px;
  box-shadow: inset -4px -4px 2px #ffffff, inset 2px 2px 2px #e5e5e5;
  width: 13%;
}

.BMS_item {
  width: 13%;
  height: 90%;
  border: 1px solid #dedede;
  border-radius: 5px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, -1px -1px 1px #f5f5f5;
}

.BMS_item_title {
  font-size: 0.65vw;
  color: #8e959b;
  font-weight: 700;
  margin-left: 5%;
  margin-top: 5%;
}

.site_dispenser_titleList {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 20%;
  color: #5a6268;
}

.site_dispenser_titleA {
  color: #5a6268;
  font-weight: 650;
  font-size: 0.8vw;
  text-align: center;
}

.site_dispenser_titleB {
  color: #117ca4;
  font-weight: 350;
  font-size: 0.8vw;
  text-align: center;
  width: 70%;
}

.site_jule {
  border: 1px solid #dedede;
  border-radius: 15px;
  height: 85%;
  width: 17%;
  transition: width 0.5s, height 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380;
}

.site_jule:hover {
  height: 90%;
  width: 22%;
}

.site_jule_title {
  font-size: 0.8vw;
  font-weight: 700;
  color: #5a6268;
  margin-top: 30%;
  margin-left: 5%;
}

.dashboard_page {
  position: absolute;
  width: 100%;
  height: 100%;
}
