.main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f2f1f1;
    padding: 5px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 30%;
    height: 30%;
    border: 4px solid #828282; 
}
.button_row{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000;
}
.modal_sent{
    display: flex;
    align-items: center;
    justify-content: center;
}