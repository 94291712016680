.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.header {
    display: flex;
    margin-left: 5%;
    margin-bottom: 2%;
}

.icon {
    width: 10%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary {
    margin-left: 2%;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #6C757D;
    font-size: 1.2vw;
}

.severity {
    margin-left: 17%;
    font-weight: 600;
    color: #6C757D;
}

.severity-text {
    margin-left: 17%;
    margin-bottom: 2%;
    color: #707070;
}

.action {
    margin-left: 17%;
    font-weight: 600;
    color: #6C757D;
}

.action-text {
    color: #707070;
    margin-left: 17%;
    margin-bottom: 2%;
}

.button {
    border-radius: 10px;
    box-shadow: 5px 5px 5px #c8c7c7, -3px -3px 3px white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 10%;
    background-color: #117CA4;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.comp-title {
    font-weight: 600;
    color: #6C757D;
    float: left;
}

.comp-content {
    margin-left: 3%;
    color: #707070;
}

.issued {
    margin-bottom: 2%;
    padding-left: 17%;
}

.battery-ready,
.reset-time,
.start-time,
.discharge,
.recharge,
.pctess,
.boost,
.rchgpower
{
    margin-left: 17%;
    margin-bottom: 2%;
}
