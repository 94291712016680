.title {
    margin-left: 7%;
    margin-top: 2%;
    color: #6C757D;
    font-weight: 650;
    font-size: 1.1vw;
    display: flex;
    align-items: center;
}


.root_container {
    border: 1px solid #DEDEDE;
    border-radius: 15px;
    box-shadow: inset 4px 4px 4px #d3d3d380, 10px 10px 10px #D6D8DA, -10px -10px 10px #F5F5F5;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.scroll_con{
    /* border: 1px solid #000000; */

    height: 90%;
    width: 100%;
    overflow-y: scroll;
    margin-top: 3%;
}
.scroll_item{
    display: flex;
    justify-content: space-around;   
    width: 100%;
    height: 28%;
    margin-bottom: 10%;
}

.con_A{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.con_B{
    height: 100%;
    width: 44%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.con_C{
    height: 100%;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status_subcontainerA_B {
    width: 100%;
    height: 70%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-shadow: inset -2px -2px 3px #ffffff, inset 4px 4px 4px #d8d8d8;
}

.statusItem_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4%;
    margin-bottom: 4%;

    width: 100%;
    height: 24%;
}



.statusText {
    text-align: center;
    margin-left: 6%;

    color: #6C757D;
    font-size: 1vw;
    font-weight: 500;

    display: inline-block;

}

.statusIndicator {
    text-align: center;
    color: #0f8118;
    font-size: 1vw;
    font-weight: 700;
    display: inline-block;

    margin-right: 6%;
}

.details_button {
    cursor: pointer;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #c8cacc, -5px -5px 5px #f4f6f8;
    background-color: #f2f1f1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c757e;
    margin-top: 5%;
    font-weight: 600;
}
