.root_container {
    display: flex;
    height: 100%;
    overflow: hidden;
    background-color: #f2f1f1;
}

.main_container {
    width: 100%;
    height: 100%;
    background-color: #f2f1f1;;
}

.main_container2 {
    height: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.mid_container {
    height: 95%;
    width: 22%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    border: 1px solid #d3d3d3;
    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;
    border-radius: 15px;
}

.mid_container_left_header {
    height: 8%;
    width: 90%;
    margin-left: 3%;
    margin-top: 5%;
    color: #464a4d;
    font-size: 1.2vw;
    font-weight: 500;
}

.mid_container_left_progressbarlist {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.2vw;
    justify-content: space-around;
    align-items: center;
}

.mid_container_left_column {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.mid_container_left_progressbar {
    width: 28%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
}

.mid_container_left_bottomline {
    height: 20%;
    width: 45%;
    color: #5a6268;
    font-size: 1vw;
    font-weight: 400;
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: right;
}


.voltage_bottomline {
    height: 20%;
    width: 100%;
    margin-bottom: 1%;
    padding-top: 15%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #70767a;
    font-size: 1vw;
    font-weight: 400;
}


.bottom_container_header {
    width: 100%;
    height: 9%;
    margin-left: 5%;
    margin-top: 1%;
    color: #464a4d;
    font-size: 1.2vw;
    font-weight: 500;
}


.bottom_container_right_temp {
    width: 16%;
    height: 100%;
}



.diagnostic_SE_BS_con {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 72%;
    height: 100%;
}

.diagnostic_SE_con {
    height: 57%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #d3d3d3;
    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;
    border-radius: 15px;
}
.diagnostic_SE_upper_con {
    width: 100%;
    height: 55%;
    display: flex;
  }

  .diagnostic_SE_upper_con_A {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 2%;
  }

  .diagnostic_SE_upper_A_title {
    margin-left: 7%;
    margin-top: 5%;
    color: #464a4d;
    font-size: 1.2vw;
    font-weight: 500;
  }
  .diagnostic_SE_upper_A_status_row {
    margin-left: 8%;
    margin-top: 3%;
  
    width: 90%;
    height: 6%;
    display: flex;
    align-items: center;
  }
  .diagnostic_SE_upper_A_status_bar1 {
    width: 10%;
    height: 100%;
    margin-right: 5px;
  
    border-radius: 20px;
    background-color: orange;
  }
  
  .diagnostic_SE_upper_A_status_text {
    width: 30%;
    height: 100%;
    margin-left: 2%;
    color: #464a4d;
    font-size: 0.9vw;
    font-weight: 400;
  
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .diagnostic_SE_upper_A_status_bar2 {
    width: 10%;
    height: 100%;
    margin-right: 5px;
  
    margin-left: 5%;
    border-radius: 20px;
    background-color: red;
  }
  .diagnostic_SE_upper_A_title3 {
    margin-left: 10%;
    margin-top: 5%;
  
    color: #368736;
    font-size: 2.5vw;
    font-weight: 600;
  }
  .diagnostic_SE_con1 {
    margin-top: 3%;
    width: 100%;
    max-height: 35%;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .diagnostic_SE_con1_row {
    margin-left: 10%;
    width: 90%;
    display: flex;
    align-items: center;
  
    color: #70767a;
  }
  
  @media only screen and (min-width: 1280px) {
    .diagnostic_SE_upper_A_title3 {
      margin-left: 10%;
      margin-top: 1%;
  
      color: #368736;
      font-size: 2.3vw;
      font-weight: 600;
    }
  
    .diagnostic_SE_con1 {
      margin-top: 1%;
      width: 100%;
  
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  
    .diagnostic_SE_con1_row {
      margin-left: 10%;
      width: 90%;
      display: flex;
      align-items: center;
  
      color: #70767a;
      flex: 1 1 auto;
    }
  }


.diagnostic_SE_con1_param {
    width: 50%;
    font-size: 1.5vh;
  }

  .diagnostic_SE_con1_value {
    font-size: 0.9vw;
  }

  .diagnostic_SE_upper_con_B {
    width: 35%;
    height: 100%;
    border-left: 1px solid #bdbbbb;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .diagnostic_SE_upper_row {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .diagnostic_SE_upper_row_text {
    width: 40%;
    height: 100%;
    color: #7e848a;
    font-size: 1vw;
    font-weight: 400;
    display: flex;
    align-items: center;
    text-align: left;
  }

.diagnostic_SE_upper_row_indicator {
    margin-left: 5%;
    width: 25%;
    height: 45%;
    border: 1px solid #eaeaea;
    box-shadow: inset -4px -4px 3px #fefefe, inset 2px 2px 3px #dbdada;
    border-radius: 15px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #368736;
    font-size: 0.8vw;
    font-weight: 700;
  }



.diagnostic_SE_lower_con {
    width: 100%;
    height: 45%;
  
    border-top: 1px solid #bdbbbb;
  
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .diagnostic_SE_lower_progressbar {
    height: 100%;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 1vw;
  }

  .prog_circle {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 1vw;  }
  
  .diagnostic_BS_con {
    height: 37%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #d3d3d3;
    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380,
      10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
    border-radius: 15px;
  }
  

  .diagnostic_BS_temp_con {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  
      