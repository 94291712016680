@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.root {
  background-color: #f2f1f1;
  width: 100%;
  height: 87vh;
  font-family: "Nunito", sans-serif;
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 0px;
  box-sizing: border-box;
  display: flex;
  gap: 25px;
  --border-radius: 10px;
}

.main_container {
  background-color: #f2f1f1;
  padding-top: 10px;
  height: 100%;
}

.root table,
th,
thead,
td {
  border: none;
}

.costProfile {
  height: auto;
}

.table {
  height: auto;
  flex-grow: 1;
}

.table > div {
  height: 100%;
  overflow-y: auto;
  background-color: #f2f1f1;
}

.root button {
  height: auto;
  flex-shrink: 0;
  width: 100%;
  min-width: auto;
}

@media (max-width: 1000px) {
  .root {
    flex-direction: column;
  }
}
