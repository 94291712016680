.root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f2f1f1;
  height: auto;
  border: 1px solid #d3d3d3;
    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;
  border-radius: 15px;
  font-weight: 700;
  font-family: 'open sans', sans-serif;
}

.buttonContainer {
  display: flex;
  padding: 16px 12px 0 12px;
  width: 100%;
  box-sizing: border-box;
}

.tableContainer {
  overflow: auto;
  height: 100%;
}