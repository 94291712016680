.container {
    width: 100%;
    height: 100%;
    border: 1px solid #dedede;
    border-radius: 15px;
    background-color: #f2f1f1;
    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;
}

.title {
    width: 95%;
    height: 5%;
    margin-top: 2%;

    margin-left: 4%;
    align-self: flex-start;

    color: #6c757e;
    font-weight: 600;
    font-size: 1.1vw;
    display: flex;
    align-items: center;
}

.Allactivity_category {
    width: 100%;
    height: 5%;
    color: #84898e;
    font-weight: 600;
    font-size: 1vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Allactivity_category_selected {
    color: #8e959b;
    text-decoration: underline;
    text-decoration-color: #3a87a3;
    color: #3a87a3;
    text-decoration-thickness: 20%;
}

.activity_container {
    width: 100%;
    height: 85%;
    overflow-y: scroll;
    margin-top: 5%;
}

.item_con {
    border-bottom: 1px solid black;
    width: 100%;
    height: 18%;
    margin-bottom: 2%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
}

.item_mid {
    width: 55%;
    height: 100%;
    margin-left: 4%;
    font-size: 0.8vw;
    color: #6c757d;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.item_right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.modal_con {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.buttonStyle {
    border-radius: 10px;
    box-shadow: 5px 5px 5px #c8c7c7, -3px -3px 3px white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mode_button_con {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 2%;
    height: 4%;
    cursor: pointer;
}
.mode_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;

    font-weight: 600;
}

.datepicker__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 4%;
}

.datepicker_starttime {
    display: flex;
    width: 50%;
    height: 100%;
}

.datepicker_endtime {
    display: flex;
    margin-left: 10%;
    width: 50%;
    height: 100%;
}
