.root_container {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
}
.main_container {
    border: 1px solid #d3d3d3;
    border-radius: 15px;
    height: 80%;
    width: 90%;
    margin: auto;
    margin-top: 2%;
    box-shadow: inset -4px -4px 3px #F9F9F9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #D6D8DA, -10px -10px 10px #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divB {
    height: 10%;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divB_A {
    margin-top: 3%;
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.divB_A_item1 {
    border-right: 1px solid #000;
    padding-left: 3%;
    padding-right: 3%;
    height: 45%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7e868f;
    font-weight: 500;
    font-size: 1.1vw;
}
.divB_A_item2 {
    height: 45%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7e868f;
    font-weight: 500;
    font-size: 1.1vw;
}

.divB_A_subitem {
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 3px #b4b4b4, -2px -2px 3px #eeeeee;
    border-radius: 10px;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #484c52;
}

.divB_A_subitemB {
    cursor: pointer;
}

.divC {
    border: 1px solid #000;
    height: 65%;
    width: 80%;
    margin-top: 2%;
    background-color: #ffffff;
}