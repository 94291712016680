.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
    margin-bottom: 2%;

}

.start {
    display: flex;
    width: 50%;
    height: 100%;
   
}

.end {
    display: flex;
    margin-left: 10%;
    width: 50%;
    height: 100%;
}
