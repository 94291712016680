.root_container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f2f2f2;
}

.right_container {
  width: 100%;
  height: 100%;
}

.main_container {
  height: 80%;
  width: 90%;
  margin: auto;
  margin-top: 2%;

  display: flex;
  justify-content: space-around;
}

.headerA {
  font-size: 1.3vw;
  color: #5a6268;
  font-weight: 600;
}

.headerC {
  font-size: 0.9vw;
  color: #5a6268;
  font-weight: 500;
}

.headerD {
  font-size: 0.85vw;
  color: #7a8086;
  font-weight: 300;
}

.button_border {
  border-radius: 10px;
  box-shadow: 5px 5px 5px #c8c7c7, -3px -3px 3px white;
  cursor: pointer;
}

.con_border {
  border: 1px solid #cecccc;
  box-shadow: inset -3px -3px 3px #f8f7f7, inset 3px 3px 3px #e4e3e3, 10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
  border-radius: 15px;
}

.main_con_left {
  width: 46%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main_con_right {
  width: 46%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bottom_left {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top_right {
  font-size: 0.95vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom_leftA {
  width: 90%;
  height: 15%;
  margin-bottom: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom_leftB {
  border-bottom: 1px solid #cecece;

  width: 90%;
  height: 15%;

  display: flex;
  align-items: center;
}


.bottom_leftB_param {
  display: flex;
  align-items: center;
  width: 55%;
  height: 100%;
}

.permission {
  margin-top: 3%;
}

.modal_con {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.modal_button_con {
  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 15%;
}

.modalMiddle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal_sent {
  height: 100%;
  width: 20%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ok {
  height: 20%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #117ca4;
  color: white;
}

.send_request {
  background-color: #117ca4;
  color: white;
}

.user_group_con {
  display: "flex";
  font-size: "1.2vw";
  justify-content: "space-between";
  width: "35%";
  height: "45%";
  align-items: "center";
  margin-top: "2%";
}
.user_group_value {
  font-size: "1.2vw";
  font-weight: 500;
  color: "#127ca4";
}

.profile_image {
  max-height: 50px;
}

.bottom_leftB_last {
  width: 90%;
  height: 15%;

  display: flex;
  align-items: center;
  overflow: unset;
}
