.main_container {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    align-items: center;
    justify-content: space-around;
}

.body_con {
    width: 100%;
    height: 84%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.left_con {
    border: 1px solid #d3d3d3;
    border-radius: 13px;

    height: 100%;
    width: 39%;
    margin: auto;
    margin-top: 2%;

    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.right_con {
    border: 1px solid #d3d3d3;
    border-radius: 13px;

    height: 100%;
    width: 54%;
    margin: auto;
    margin-top: 2%;

    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.left_A {
    /* border: 1px solid #000000; */

    width: 90%;
    height: 10%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left_A_A {
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.left_B {
    border: 1px solid #eaeaea;
    border-radius: 13px;
    box-shadow: inset 5px 5px 5px #d7d7d7, inset -5px -5px 5px #fff;

    width: 90%;
    height: 25%;

    display: flex;
    justify-content: space-around;
}

.left_B_column {
    /* border: 1px solid #000000; */

    width: 33%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.left_B_column_header {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 25%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.3vw;
    font-weight: 400;
    color: #5b656d;
}

.left_B_column_body {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 75%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    color: #99a1a8;
    font-size: 1.2vw;
}

.left_C {
    /* border: 1px solid #000000; */

    width: 90%;
    height: 45%;
}

.left_C_header {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 20%;

    display: flex;
    align-items: center;

    font-size: 1.2vw;
    color: #6c757e;
    font-weight: 450;
}

.left_C_body {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 80%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    color: #9099a0;
    font-size: 1vw;
}

.left_C_row {
    /* border: 1px solid #000000; */

    margin-left: 5%;
    width: 95%;

    display: flex;
}

.left_C_param {
    /* border: 1px solid #000000; */

    width: 75%;
}


.left_D {
    /* border: 1px solid black; */
    width: 100%;
    height: 14%;
    margin-top: 4%;

    display: flex;
    flex-direction: row;
    align-items: center;
}


.right_A {
    /* border: 1px solid #000000; */

    width: 90%;
    height: 10%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right_A_A {
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.right_B {
    border: 1px solid #eaeaea;
    border-radius: 13px;
    box-shadow: inset 5px 5px 5px #d7d7d7, inset -5px -5px 5px #fff;

    width: 90%;
    height: 50%;

    display: flex;
    justify-content: space-around;
}

.right_B_column {
    /* border: 1px solid #000000; */

    width: 33%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.right_B_column_header {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 17%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1.3vw;
    font-weight: 300;
    color: #495057;
}

.right_B_column_body {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    color: #99a1a8;
    font-size: 1.2vw;
}

.right_C {
    /* border: 1px solid #000000; */

    width: 90%;
    height: 35%;
    margin-top: 3%;

    display: flex;
}

.right_C_left {
    /* border: 1px solid #000000; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 42px;
    width: 50%;
    height: 75%;
}

.right_C_header {
    /* border: 1px solid #000000; */

    width: 100%;
    height: 20%;

    display: flex;
    align-items: center;

    font-size: 1.2vw;
    color: #6c757e;
    font-weight: 450;
}

.right_C_left_header_row {
    display: flex;
    align-items: center;
    height: 10%;
    font-size: 1.2vw;
}

.right_C_left_header_A {
    width: 50%;
    font-size: 1.2vw;
    font-weight: 300;
    color: #5c6268;
}

.right_C_left_header_B {
    width: 40%;
    display: flex;
    align-items: center;
    margin-left: 30px;
    justify-content: center;
}

.right_C_left_row {
    margin-top: 10px;
    display: flex;
}

.right_C_left_body {
    /* border: 1px solid #000000; */
    width: 100%;
    height: 80%;
    margin-top: 7%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.right_C_left_param {
    /* border: 1px solid #000000; */

    width: 60%;
    height: 100%;

    font-weight: 350;
    font-size: 1.1vw;
    color: #5c6268;
}

.right_C_left_value {
    border: 1px solid #eaeaea;
    border-radius: 15px;
    background-color: #fff;

    height: 100%;
    width: 40%;
    min-width: 45px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #69a568;
    box-shadow: inset 1px 1px 1px #9099a0;

    font-size: 1.2vw;
}

.right_C_right {
    width: 50%;
    height: 100%;
}

.right_C_right_header {
    /* border: 1px solid #000000; */

    margin-left: 10%;

    font-weight: 500;
    font-size: 1.2vw;
    color: #757c81;

    width: 90%;
    height: 10%;
}

.right_C_right_body {
    /* border: 1px solid #000000; */

    margin-left: 15%;

    width: 80%;
    height: 90%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.right_C_right_row {
    /* border: 1px solid #000000; */
    display: flex;
}

.right_C_right_param {
    /* border: 1px solid #000000; */

    width: 60%;
    height: 100%;

    font-weight: 350;
    font-size: 1.1vw;
    color: #5c6268;
}

.right_C_right_value {
    border: 1px solid #eaeaea;
    border-radius: 15px;
    background-color: #fff;

    height: 100%;
    width: 25%;
    min-width: 80px;
    text-align: center;

    color: #69a568;
    box-shadow: inset 1px 1px 1px #9099a0;
    font-size: 1.2vw;
}

.buttonStyle {
    border-radius: 10px;
    box-shadow: 5px 5px 5px #c8c7c7, -3px -3px 3px white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;
    width: 30%;
    height: 40%;
    margin-left: 25px;
    margin-right: 25px;
}

.buttonText {
    font-size: 0.85vw;
    font-weight: 500;
    color: #6c757e;
}

.left_A_A_item {
    color: #6c757e;
    font-size: 1.3vw;
    font-weight: 600;
}
.left_D {
    display: flex;
    margin-top: 2%;
    // margin-left: 10%;
    // margin-right: 10%;
    width: 95%;
    justify-content: space-around;
}

.power_conversion{
    color: #6c757e;
    font-size: 1.3vw;
    font-weight: 600;
}
