.mainCon {
    background-color: #404040;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;

    box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
        -10px -10px 10px #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 15px;
    background-color: #f2f1f1;
}
.titleCon {
    font-weight: 700;
    color: #5a6268;
    font-size: 1.5vw;
    height: 13%;
    display: flex;
    align-items: center;
    margin-left: 5%;
}
.subtitle{
    font-weight: 500;
    color: #5a6268;
    font-size: 1.1vw;
}
.bodyCon {
    display: flex;
    width: 100%;
    height: 85%;
    margin-top: 2%;
}
.cellCon {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    width: 20%;
}
.voltageCon {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    width: 45%;
}
.tempCon {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    width: 33%;
}

.dataRow {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dataLabel {
    margin-left: 4%;
    width: 40%;
}
.degreeCol {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
    margin-top: 5%;
}
