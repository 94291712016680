.meter_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #8f959c;
}

.meter_title {
  width: 100%;
  height: 20%;

  font-size: 1vw;
  font-weight: 400;
  color: #70767a;

  text-align: center;
}

.indicator_con {
  width: 75%;
  height: 55%;

  border: 1px solid #eaeaea;
  border-radius: 10px;

  box-shadow: inset 4px 4px 4px #dcdcdc, inset -2px -2px 4px #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom_indicator {
  width: 100%;
  height: 40%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.3vw;
  font-weight: 650;
  color: #8e959b;

  text-align: center;
}

.body_container {
  width: 100%;
  height: 40%;
}

.scale_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 25%;
}

.scale {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.6vw;
  height: 100%;
  width: 12%;
}

.scale_line {
  width: 1%;
  height: 50%;
  border-left: 1px solid #928d8d;
}

.scale_text {
  font-size: 0.5vw;
  width: 100%;
  height: 50%;
  text-align: center;
}

.indicator_container {
  display: flex;
  align-items: flex-end;
  border: 1px solid #eaeaea;
  border-radius: 25px;

  box-shadow: inset 1px 1px 1px #eef0ee;

  background-color: #ffffff;
  width: 100%;
  height: 30%;
}

.blue_indicator {
  width: 50%;
  height: 100%;
  background-color: #378735;

  border-radius: 25px;
  box-shadow: inset -4px -4px 3px #168e48, inset 4px 4px 4px #54be74;
}

.orange_indicator {
  width: 50%;
  height: 100%;
  background-color: #c70402;

  border-radius: 25px;
  box-shadow: inset -4px -4px 3px #d04431, inset 4px 4px 4px #fb8c70;
}

.yellow_indicator {
  width: 50%;
  height: 100%;
  background-color: #ea9c00;

  border-radius: 25px;
  box-shadow: inset -4px -4px 3px #d04431, inset 4px 4px 4px #fb8c70;
}
