.root_container {
  width: 100%;
  height: 100%;
  background-color: #f2f1f1;
}

.main_container {
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.main_left_container {
  height: 90%;
  width: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 15px;
  box-shadow: inset 4px 4px 4px #d3d3d380, 10px 10px 10px #d6d8da,
    -10px -10px 10px #f5f5f5;
}

.main_mid_container {
  height: 90%;
  width: 32%;
}

.main_right_container {
  height: 90%;
  width: 25%;
}

.dispenserTitle {
  height: 3%;
  margin-left: 5%;
  align-self: flex-start;

  font-weight: 700;
  color: #5a6268;
  font-size: 1.1vw;
  display: flex;
  align-items: center;
}

.capacity {
  margin-top: 0%;
  margin-bottom: 0%;
  width: 90%;
  height: 10%;
}

.dispenser_info {
  width: 80%;
  height: 35%;
  margin-left: 5%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.dispenser_row {
  text-align: end;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  border-bottom: white solid 0.4px;
}

.dispenser_text {
  width: 60%;
  color: #4f555a;
  font-size: 0.8vw;
  font-weight: 600;
  display: flex;
  justify-content: right;
  align-items: center;
}

.dispenser_indicator {
  width: 25%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727b82;
  font-size: 0.9vw;
  font-weight: 600;
  margin-left: 5%;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: inset 1px 1px 2px #e3e3e3;
}

.link_container {
  width: 50%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.link_item {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #c8cacc, -5px -5px 5px #f4f6f8;
  background-color: #f2f1f1;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757e;
  font-size: 1.2vw;
  font-weight: 600;
}

.daily_charging{
  margin-top: 2%;
}