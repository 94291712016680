.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 2%;
    height: 4%;
    cursor: pointer;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    font-weight: 600;
    border-top-left-radius: "10px";
    border-bottom-right-radius: "10px";
}
