.root_container {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

.navbar {
  width: 100%;
}

.main_container {
  width: 100%;
  height: 91%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #eaeaea;
}

.submain_container {
  display: flex;
  justify-content: space-around;
  align-items: center;

  background-color: #eaeaea;

  width: 95%;
  height: 90%;

  margin: auto;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380,
    10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
}

.location_list__container {
  width: 95%;
  height: 90%;
  margin: auto;
  border-radius: 15px;

}

.location_list__title {
  margin: 10px auto;
  height: auto;
  position: relative;
  font-size: 1.2vw;
  font-weight: 600;
}

.location_list__table{
  th{
    background-color: #6c757e;
    color: white;
    font-weight: 500;
  }
  border: none !important;
  .connection_status {
    
    &__connected{
      font-weight: bold;
      color: green;
    }
    &__disconnected{
      font-weight: bold;
      color: red;
    }
    &__not_commissioned{
      font-weight: bold;
      color: grey;
    }
  }
}


.collapsible_row{
  display: flex;
  flex-direction: column;
  .collapsible_info{
    margin: 1% 0 ;
    display: flex;
    flex-flow: wrap;
    b{
      margin-right: 2% ;
    }
  }
}



.customer_name {
  margin: 0%;
  margin-left: 10%;
  margin-top: 7%;
  color: #348cae;
  font-size: 1.4vw;
}

.store_number {
  font-weight: 300;
  margin-left: 10%;
  margin-bottom: 0%;
  color: #6c757d;
  font-size: 1.2vw;
}

/* TO DO: SCSS - can create a base style and use extend for other styles inhering from this base */
.store_name {
  margin: 0%;
  margin-top: 2%;
  font-weight: 400;
  margin-left: 10%;
  color: #4b4f53;
  font-size: 1vw;
}

.store_location {
  margin: 0%;
  margin-top: 2%;
  font-weight: 400;
  margin-left: 10%;
  color: #4b4f53;
  font-size: 1vw;
}

.boarding_con_right {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  width: 22%;
  height: 90%;
  margin: auto;
  background-color: #eaeaea;
  border-radius: 15px;
  box-shadow: inset -4px -4px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380,
    5px 5px 5px #d6d8da, -5px -5px 5px #f5f5f5;
}

.boarding_station_container {
  box-shadow: inset -2px -2px 3px #f9f9f9, inset 4px 4px 4px #d3d3d380;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  width: 80%;
  align-self: center;
  margin-top: 2%;
  padding: 3% ;
  display: flex;
  flex-direction: column;

}

.boarding_station_row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.boarding_station_status {
  margin: 0;
  font-weight: 400;
  color: #484c4f;
  font-size: 1vw;
}

.boarding_station_connected {
  margin: 0;
  font-weight: 400;
  color: green;
  font-size: 1vw;
}

.site_overview_detail {
  font-weight: 400;
  width: 60%;
  height: 6%;
  align-self: center;
  box-shadow: 10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
  text-align: center;
  border-radius: 10px;
  font-size: 0.9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-bottom: 6%;
  margin-top: 4%;
}

.button__back {
  font-weight: 400;
  width: 10%;
  height: 5%;
  align-self: flex-start;
  // box-shadow: 10px 10px 10px #d6d8da, -10px -10px 10px #f5f5f5;
  text-align: center;
  border-radius: 10px;
  // border-color: #d6d8da;
  font-size: 0.9vw;
  cursor: pointer;
  margin-bottom: 0%;
  margin-left: 4%;
  margin-top: 4%;
}
