.root {
  display: flex;
  padding: 8px;
  background-color: #f2f1f1;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  align-items: center;
  gap: 15px;
}

.title {
  margin-top: 0.1rem;
  margin-right: 2rem;
  font-size: 1rem;
  color: var(--text-black);
  font-weight: 600;
}


.right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
